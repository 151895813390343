import * as React from 'react';
import Select from 'react-select';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { pullEmployeesByCompany, pullProperties, pullPropertiesForRegionals } from '../../../functions/fetchLinkedObjects';
import { EmployeeCompany, NewEmployeeRoles } from '../../../constants/EnumConstants';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import axios from 'axios';
import { EMPTY_GUID } from '../../../constants/DefaultConstants';
import { getUserID, getUserJobFromServer } from '../../../functions/authActions';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface EmployeePositionChangeRequestModalState {
  showing: boolean;
  tab: number;
  effectiveDates: Array<SelectOptions>;
  effectiveDate: SelectOptions;
  employees: Array<SelectOptions>;
  employee: SelectOptions;
  errorMessage: string;
  currentPosition: string;
  classificationChangeData: ClassificationChangeData;
  allocationData: AllocationChangeRequestModalReferenceData;
}

interface ClassificationChangeData {
  newPosition: string;
  oldHours: number;
  newHours: number;
  oldSalary: number;
  newSalary: number;
  oldHourly: number;
  newHourly: number;
  oldHousing: SelectOptions;
  newHousing: SelectOptions;
  oldMileage: number;
  newMileage: number;
  oldPhone: number;
  newPhone: number;
}

const ClassificationChangeDataBase = {
  newPosition: "",
  oldHours: 0,
  newHours: 0,
  oldSalary: 0,
  newSalary: 0,
  oldHourly: 0,
  newHourly: 0,
  oldHousing: { label: "N/A", value: "0"},
  newHousing: { label: "N/A", value: "0" },
  oldMileage: 0,
  newMileage: 0,
  oldPhone: 0,
  newPhone: 0
}

export default class EmployeePositionChangeRequestModal extends React.Component<{}, EmployeePositionChangeRequestModalState> {

  allocationModal: React.RefObject<AllocationsManagementComponent> = React.createRef<AllocationsManagementComponent>();
  constructor(props: {}) {
    super(props);
    this.state = {
      showing: false,
      tab: 0,
      effectiveDates: [],
      effectiveDate: null,
      employees: [],
      employee: { label: "Select Employee", value: "" },
      errorMessage: "",
      currentPosition: "",
      classificationChangeData: ClassificationChangeDataBase,
      allocationData: null
    }
    this.updateTab = this.updateTab.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);
    this.changePosition = this.changePosition.bind(this);
    this.changeNewPosition = this.changeNewPosition.bind(this);
    this.changeEffectiveDate = this.changeEffectiveDate.bind(this);
    this.changeOldHours = this.changeOldHours.bind(this);
    this.changeNewHours = this.changeNewHours.bind(this);
    this.changeOldHourly = this.changeOldHourly.bind(this);
    this.changeNewHourly = this.changeNewHourly.bind(this);
    this.changeOldSalary = this.changeOldSalary.bind(this);
    this.changeNewSalary = this.changeNewSalary.bind(this);
    this.changeOldHousing = this.changeOldHousing.bind(this);
    this.changeNewHousing = this.changeNewHousing.bind(this);
    this.changeOldMileage = this.changeOldMileage.bind(this);
    this.changeNewMileage = this.changeNewMileage.bind(this);
    this.changeOldPhone = this.changeOldPhone.bind(this);
    this.changeNewPhone = this.changeNewPhone.bind(this);
    this.fullSubmit = this.fullSubmit.bind(this);
    this.show = this.show.bind(this);
    this.advanceProfile = this.advanceProfile.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let employees = await pullEmployeesByCompany(EmployeeCompany.VMI);
    this.setState({
      employees: employees
    })
    this.generateEffectiveDates();
  }

  show(): void {
    this.setState({showing: true})
  }

  getTab(): JSX.Element {
    switch (this.state.tab) {
      case 0:
        return this.getProfileData();
      case 1:
        return this.getClassificationData();
      case 2:
        return <AllocationsManagementComponent updateTab={this.updateTab} ref={this.allocationModal} employee={this.state.employee} />
      case 3:
        return this.getWarningMessage();
      case 4:
        return <div className="lds-dual-ring" style={{ marginLeft: "42%", marginTop: "3%", verticalAlign: "center" }}></div>
    }
  }

  async generateEffectiveDates(): Promise<void> {
    // this needs to be replaced with an axios call
    let response: any = await axios.get('./api/payroll-effective-dates/six-periods');
    console.log(response);
    this.setState({
      effectiveDates: response.data,
      effectiveDate: response.data[0]
    })
  }

  //#region Change Functions
  changeEmployee(event: SelectOptions | null) {
    if (event === null) { return; }
    this.setState({ employee: event });
  }

  changePosition(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ currentPosition: event.target.value });
  }

  changeEffectiveDate(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({ effectiveDate: event })
  }

  changeNewPosition(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.newPosition = event.target.value;
    this.setState({ classificationChangeData: data });
  }

  changeOldHours(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.oldHours = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeNewHours(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.newHours = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeOldSalary(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.oldSalary = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeNewSalary(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.newSalary = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeOldHourly(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.oldHourly = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeNewHourly(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.newHourly = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeOldHousing(event: SelectOptions | null): void {
    let data = this.state.classificationChangeData;
    data.oldHousing = event;
    this.setState({ classificationChangeData: data });
  }

  changeNewHousing(event: SelectOptions | null): void {
    let data = this.state.classificationChangeData;
    data.newHousing = event;
    this.setState({ classificationChangeData: data });
  }

  changeOldMileage(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.oldMileage = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeNewMileage(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.newMileage = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeOldPhone(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.oldPhone = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }

  changeNewPhone(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.classificationChangeData;
    data.newPhone = parseFloat(event.target.value);
    this.setState({ classificationChangeData: data });
  }
  //#endregion

  advanceProfile(): void {
    if (this.state.employee.value === "") {
      this.setState({ errorMessage: "Employee must be defined before continuing." })
      return;
    }
    else if (this.state.currentPosition === "") {
      this.setState({ errorMessage: "Please add the employees position before continuing." });
      return;
    }
    this.setState({ tab: 1, errorMessage: "" })
  }

  getProfileData(): JSX.Element {
    return <>
      <Row>
        <Col xs='3'>
          <label>Employee</label>
        </Col>
        <Col>
          <Select
            styles={reactSelectBasicStyle}
            options={this.state.employees}
            value={this.state.employee}
            onChange={this.changeEmployee}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Position</label>
        </Col>
        <Col>
          <input type="text" value={this.state.currentPosition} className="standard-input" onChange={this.changePosition}  />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Date</label>
        </Col>
        <Col>
          <label> {new Date().toLocaleDateString('en-CA')}</label>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Date Effective</label>
        </Col>
        <Col>
          <Select
            styles={reactSelectBasicStyle}
            options={this.state.effectiveDates}
            onChange={this.changeEffectiveDate}
            value={this.state.effectiveDate}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
        </Col>
        <Col>
          <input type="button" value="Next" className="standard-input" onClick={this.advanceProfile} />
        </Col>
      </Row>
    </>
  }

  getClassificationData(): JSX.Element {
    return <>
      <label style={{ width: "100%", textAlign: "center" }}>
        Please note that property changes will appear on the next page. Additionally, if you do not edit the values, they will automatically be ignored. 
      </label>
      <hr />
      <Row>
        <Col xs='3'>
          <h4>Change</h4>
        </Col>
        <Col>
          <h4>Old Information</h4>
        </Col>
        <Col>
          <h4>New Information</h4>
        </Col>
      </Row>
      <Row>
        <Col xs='3'>
          <label>Position</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Title:</label>
            </Col>
            <Col>
              <label>{this.state.currentPosition}</label>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Title:</label>
            </Col>
            <Col>
              <input type="text" value={this.state.classificationChangeData.newPosition} className="standard-input" onChange={this.changeNewPosition} />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Hours Per Week</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Hours:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.oldHours} className="standard-input" onChange={this.changeOldHours} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Hours:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.newHours} className="standard-input" onChange={this.changeNewHours} />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Salary</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Monthly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.oldSalary} className="standard-input" onChange={this.changeOldSalary} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Monthly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.newSalary} className="standard-input" onChange={this.changeNewSalary} />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Hourly</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Hourly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.oldHourly} className="standard-input" onChange={this.changeOldHourly} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Hourly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.newHourly} className="standard-input" onChange={this.changeNewHourly} />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Housing</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Yes/No</label>
            </Col>
            <Col>
              <Select
                styles={reactSelectBasicStyle}
                options={[{ label: "Yes", value: "2" }, { label: "No", value: "1" }]}
                value={this.state.classificationChangeData.oldHousing}
                onChange={this.changeOldHousing}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Yes/No</label>
            </Col>
            <Col>
              <Select
                styles={reactSelectBasicStyle}
                options={[{ label: "Yes", value: "2" }, { label: "No", value: "1" }]}
                value={this.state.classificationChangeData.newHousing}
                onChange={this.changeNewHousing}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs='3'>
          <label>Mileage Allowance</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Monthly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.oldMileage} className="standard-input" onChange={this.changeOldMileage} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Monthly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.newMileage} className="standard-input" onChange={this.changeNewMileage} />
            </Col>
          </Row>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs='3'>
          <label>Phone Allowance</label>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Monthly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.oldPhone} className="standard-input" onChange={this.changeOldPhone} />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col xs='3'>
              <label>Monthly:</label>
            </Col>
            <Col>
              <input type="number" value={this.state.classificationChangeData.newPhone} className="standard-input" onChange={this.changeNewPhone} />
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <input type="button" value="Back" className="standard-input" onClick={() => this.setState({ tab: 0 })} />
        </Col>
        <Col>
          <input type="button" value="Next" className="standard-input" onClick={() => this.setState({ tab: 2 })} />
        </Col>
      </Row>
    </>
  }

  getWarningMessage(): JSX.Element {
    return (
      <>
        <h5>
          We appreciate you using the employee change system. After this form is submitted, depending on the changes requested you will
          receive a file to sign using via Adobe Sign. If you have any questions about the workflow or how it runs please contact the tech team and
          they will be able to supply you with information on the process.
        </h5>
        <Row>
          <Col>
            <input type="button" value="Back" className="standard-input" onClick={() => this.setState({ tab: 2 })} />
          </Col>
          <Col>
            <input type="button" value="Request Allocation Changes" className="standard-input" onClick={this.fullSubmit} />
          </Col>
        </Row>
        
      </>
    )
  }

  async fullSubmit(): Promise<void> {
    this.setState({ tab: 4 });
    let allocData = this.state.allocationData;
    let data: any = {
      RegionalUID: getUserID(),
      EmployeeUID: this.state.employee.value,
      EffectiveDate: this.state.effectiveDate.value,
      CurrentPosition: this.state.currentPosition,
      NewPosition: this.state.classificationChangeData.newPosition,
      OldHours: this.state.classificationChangeData.oldHours,
      NewHours: this.state.classificationChangeData.newHours,
      OldSalary: this.state.classificationChangeData.oldSalary ?? 0,
      NewSalary: this.state.classificationChangeData.newSalary ?? 0,
      OldHourly: this.state.classificationChangeData.oldHourly ?? 0,
      NewHourly: this.state.classificationChangeData.newHourly ?? 0,
      OldHousing: this.state.classificationChangeData.oldHousing.value ?? 0,
      NewHousing: this.state.classificationChangeData.newHousing.value ?? 0,
      OldMileage: this.state.classificationChangeData.oldMileage ?? 0,
      NewMileage: this.state.classificationChangeData.newMileage ?? 0,
      OldPhone: this.state.classificationChangeData.oldPhone ?? 0,
      NewPhone: this.state.classificationChangeData.newPhone ?? 0,
      AssignmentChange: allocData.assignmentChange,
      AllocationChange: allocData.allocationChange,
      Maps: []
    };
    
    let total = 0;
    allocData.newAllocations.map((item) => {
      if (item.role.value === "-1") {
        this.setState({ errorMessage: "Invalid Role defined for an allocation. Please set the Role in the table below before continuing." });
        return;
      }
      total += item.allocation;
      data.Maps.push({
        UID: item.uid,
        EmpireEmployeeUID: this.state.employee.value,
        PropertyUID: item.property.value,
        EmployeeSiteRole: item.role.value,
        Allocation: item.allocation,
      });
    })
    if (total !== 100 && total !== 0) {
      this.setState({ errorMessage: "The allocations you have requested do not add up to 100%. Please fix the allocations as they are not correct. " });
      return;
    }
    let response = await axios.post('./api/regional-manager-allocation-change', data, { validateStatus: () => true });
    if (response.status === 202) {
      this.setState({ showing: false }, () => window.location.reload());
    }
    else {
      this.setState({
        tab: this.state.tab-1,
        errorMessage: "There has been an error submitting the request. Please submit a <a href=\"./ticket-submission\" target=\"_blank\" style=\"color: #ff8080; background-color:  #4C4A42; \">ticket</a> so the team is aware of the issue, and we hope you have a great day."
      })
    }
  }

  updateTab(advance: boolean) {
    let allocData: AllocationChangeRequestModalReferenceData = this.allocationModal.current.getData();
    this.setState({ allocationData: allocData });
    let tab = this.state.tab;
    tab = advance ? tab + 1 : tab - 1;
    this.setState({ tab: tab });
  }

  render(): JSX.Element {
    return (
      <Modal isOpen={this.state.showing} style={{ borderRadius: "15%", backgroundColor: "#4C4A42" }} size="lg">
        <ModalHeader tag='h3' style={{ backgroundColor: "#4C4A42" }} toggle={() => this.setState({ showing: false })}>
          <b style={{ color: "#d7c7a7", width: "100%", textAlign: "center" }}>Employee Change Request Form</b>
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#4C4A42" }}>
          {
            this.state.errorMessage !== "" && <h6 style={{ color: "#ff8080", width: "100%", textAlign: "center" }} dangerouslySetInnerHTML={{ "__html": this.state.errorMessage }}></h6>
          }
          {this.getTab()}
        </ModalBody>
      </Modal>
    )
  }
}

interface OriginalAllocationData {
  uid: string;
  employee: string;
  propertyName: string;
  allocation: number;
  role: string;
}

interface NewAllocationData {
  uid: string;
  employee: string;
  property: SelectOptions;
  allocation: number;
  role: SelectOptions;
}

interface DifferenceAllocationData {
  uid: string;
  previousProperty: string;
  newProperty: string;
  oldDistribution: number;
  newDistribution: number;
  oldRole: string;
  newRole: string;
  employee: string;
  removed: boolean;
}

interface AllocationChangeRequestModalState {
  showing: boolean;
  properties: Array<SelectOptions>;
  original: Array<OriginalAllocationData>;
  newAllocations: Array<NewAllocationData>;
  differences: Array<DifferenceAllocationData>;
  errorMessage: string;
  initialSubmit: boolean;
  assignmentChange: boolean;
  allocationChange: boolean;
}

interface AllocationChangeRequestModalReferenceData {
  original: Array<OriginalAllocationData>;
  newAllocations: Array<NewAllocationData>;
  assignmentChange: boolean;
  allocationChange: boolean;
}

class AllocationsManagementComponent extends React.Component<{ updateTab: (advance: boolean) => void, employee: SelectOptions }, AllocationChangeRequestModalState> {
  static AllocationRolesSelect = [
    { value: "0", label: "MT" },
    { value: "1", label: "ASM" },
    { value: "2", label: "SM" },
    { value: "3", label: "RM" }
  ]
  constructor(props: { updateTab: (advance: boolean) => void, employee: SelectOptions }) {
    super(props);
    this.state = {
      showing: false,
      original: [],
      newAllocations: [],
      properties: [],
      differences: [],
      errorMessage: "",
      initialSubmit: false,
      assignmentChange: false,
      allocationChange: false
    }
    this.addRow = this.addRow.bind(this);
    this.loadEmployee = this.loadEmployee.bind(this);
    this.changeProperty = this.changeProperty.bind(this);
    this.changeAllocation = this.changeAllocation.bind(this);
    this.checkAllocations = this.checkAllocations.bind(this);
    this.advance = this.advance.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let properties = (await getUserJobFromServer() === 17) ? await pullPropertiesForRegionals() : await pullProperties();
    this.setState({
      properties: properties
    }, async () => await this.loadEmployee(this.props.employee))
  }

  getHeaders(): Array<FilterAndSettingField> {
    const headers = [
      { columnName: "Employee", setting: true, filter: {} },
      { columnName: "Property", setting: true, filter: {} },
      { columnName: "Distribution", setting: true, filter: {}, additionalClasses: "twenty" },
      { columnName: "Role", setting: true, filter: {}, additionalClasses: "twenty" },
    ]
    return headers;
  }

  show(): void {
    this.setState({
      showing: true
    }, () => this.checkAllocations())
  }

  async loadEmployee(event: SelectOptions | null): Promise<void> {
    if (event === null) { return; }
    let response = await axios.get('./api/property-employee-map/employee-allocations/' + event.value);
    let _response = await axios.get('./api/property-employee-map/employee-allocation-management/' + event.value);
    let total = 0;
    _response.data.forEach((item: any) => {
      total += item.allocation
    })
    this.setState({
      original: response.data,
      newAllocations: _response.data,
      differences: [],
      errorMessage: ""
    })
  }

  getData(): AllocationChangeRequestModalReferenceData {
    return {
      original: this.state.original,
      newAllocations: this.state.newAllocations,
      assignmentChange: this.state.assignmentChange,
      allocationChange: this.state.allocationChange
    }
  }

  getBaseDiff(allocation: any): DifferenceAllocationData {
    this.setState({ errorMessage: "" })
    return {
      uid: allocation.uid,
      previousProperty: allocation.property.label,
      newProperty: allocation.property.label,
      oldRole: allocation.role.label,
      newRole: allocation.role.label,
      oldDistribution: allocation.allocation,
      newDistribution: allocation.allocation,
      employee: allocation.employee,
      removed: false
    }
  }

  changeProperty(event: SelectOptions | null, idx: number): void {
    let allocations = this.state.newAllocations;
    let allocation = this.state.newAllocations[idx];
    let difference = this.state.differences.filter(u => u.uid === allocation.uid);
    let _difference: DifferenceAllocationData;
    if (difference.length === 0) {
      _difference = this.getBaseDiff(allocation);
      _difference.previousProperty = allocation.property.label,
        _difference.newProperty = event.label;
      this.state.differences.push(_difference);
    }
    else {
      _difference = difference[0];
      let idx = this.state.differences.findIndex(u => u.uid === _difference.uid);
      _difference.newProperty = event.label;
      this.state.differences[idx] = _difference;
    }
    allocation.property = event;
    allocations[idx] = allocation;
    this.setState({
      newAllocations: allocations,
      assignmentChange: true,
      allocationChange: true
    }, () => this.checkAllocations())
  }

  changeAllocation(event: React.ChangeEvent<HTMLInputElement>, idx: number): void {
    let allocations = this.state.newAllocations;
    let allocation = this.state.newAllocations[idx];
    let difference = this.state.differences.filter(u => u.uid === allocation.uid);
    let _difference: DifferenceAllocationData;
    if (difference.length === 0) {
      _difference = this.getBaseDiff(allocation);
      _difference.oldDistribution = allocation.allocation,
        _difference.newDistribution = parseInt(event.target.value);
      this.state.differences.push(_difference);
    }
    else {
      _difference = difference[0];
      let idx = this.state.differences.findIndex(u => u.uid === _difference.uid);
      _difference.newDistribution = parseInt(event.target.value);
      this.state.differences[idx] = _difference;
    }
    allocation.allocation = parseInt(event.target.value);
    allocations[idx] = allocation;
    this.setState({
      newAllocations: allocations,
      allocationChange: true
    }, () => this.checkAllocations())
  }

  checkAllocations(): void {
    let allocations = this.state.newAllocations;
    let total = 0;
    allocations.forEach((item) => {
      total += item.allocation
    })
    this.setState({
      errorMessage: (total === 100 || total === 0) ? "" : "The Allocations you have selected do not sum to 100.",
    }, () => this.checkRoles())
  }

  checkRoles(): void {
    let roles = this.state.newAllocations;
    let error: boolean = false;
    roles.forEach((item) => {
      if (parseInt(item.role.value) < 0 || parseInt(item.role.value) > 3) {
        error = true;
      }
    })
    let message = this.state.errorMessage + (error ? (this.state.errorMessage !== "" ? "<br />" : "") + "The Roles you have selected are invalid. Please select a role for each allocation." : "")
    this.setState({
      errorMessage: message
    })
  }

  checkProperties(): void {
    let properties = this.state.newAllocations;
    let error: boolean = false;
    properties.forEach((item) => {
      if (item.property.value === "") {
        error = true;
      }
    })
    let message = this.state.errorMessage + (error ? (this.state.errorMessage !== "" ? "<br />" : "") + "An Allocation is not attached to a property. Please correct this before continuing" : "")
    this.setState({
      errorMessage: message
    })
  }

  changeRole(event: SelectOptions | null, idx: number): void {
    let allocations = this.state.newAllocations;
    let allocation = this.state.newAllocations[idx];
    allocation.role = event;
    allocations[idx] = allocation;
    let difference = this.state.differences.filter(u => u.uid === allocation.uid);
    let _difference: DifferenceAllocationData;
    if (difference.length === 0) {
      _difference = this.getBaseDiff(allocation);
      _difference.oldRole = allocation.role.label;
      _difference.newRole = event.label;
      this.state.differences.push(_difference);
    }
    else {
      _difference = difference[0];
      let idx = this.state.differences.findIndex(u => u.uid === _difference.uid);
      _difference.newRole = event.label;
      this.state.differences[idx] = _difference;
    }
    this.setState({
      newAllocations: allocations,
      allocationChange: true
    }, () => this.checkAllocations())
  }

  removeRow(idx: number): void {
    let allocations = this.state.newAllocations;
    let allocation = this.state.newAllocations[idx];
    let difference = this.state.differences.filter(u => u.uid === allocation.uid);
    let _difference: DifferenceAllocationData;
    if (difference.length === 0) {
      _difference = this.getBaseDiff(allocation);
      _difference.removed = true;
      this.state.differences.push(_difference);
    }
    else {
      _difference = difference[0];
      let idx = this.state.differences.findIndex(u => u.uid === _difference.uid);
      _difference.removed = true;
      this.state.differences[idx] = _difference;
    }
    allocations.splice(idx, 1);
    this.setState({
      newAllocations: allocations,
      assignmentChange: true,
      allocationChange: true
    }, () => this.checkAllocations())
  }

  addRow(): void {
    let allocations = this.state.newAllocations;
    let allocation: NewAllocationData = {
      uid: EMPTY_GUID,
      employee: this.props.employee.label,
      property: { label: "New", value: "" },
      allocation: 0,
      role: { label: "N/A", value: "-1" }
    }
    let difference = this.getBaseDiff(allocation);
    let differences = this.state.differences;
    differences.push(difference);
    allocations.push(allocation);
    this.setState({
      newAllocations: allocations,
      differences: differences,
      assignmentChange: true,
      allocationChange: true
    }, () => this.checkAllocations())
  }

  advance(): void {
    if (this.state.errorMessage !== "") {
      this.setState({
        errorMessage: this.state.errorMessage + "<br /> Please resolve this error before attempting to proceed."
      })
      return;
    }
    this.props.updateTab(true);
  }

  render(): JSX.Element {
    return (
      <>
        <>
          {
            this.state.errorMessage !== "" && <h6 style={{ color: "#ff8080", width: "100%", textAlign: "center" }} dangerouslySetInnerHTML={{ "__html": this.state.errorMessage }}></h6>
          }
          <Row>
            <Col>
              <h5 style={{ width: "100%", textAlign: "center" }}>Original Allocations</h5>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={0} />
                </thead>
                <tbody>
                  {
                    this.state.original.map((item) => (
                      <tr key={item.uid}>
                        <td className="fixedTableCellFirst">{item.employee}</td>
                        <td className="fixedTableCell">{item.propertyName}</td>
                        <td className="fixedTableCell">{item.allocation}</td>
                        <td className="fixedTableCell">{item.role}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <h5 style={{ width: "100%", textAlign: "center" }}>Updated Allocations</h5>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={1} />
              </thead>
              <tbody>
                {
                  this.state.newAllocations.map((item, idx) => (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst">{item.employee}</td>
                      <td className="fixedTableCell">
                        <Select
                          styles={reactSelectBasicStyle}
                          options={this.state.properties}
                          onChange={(e: SelectOptions) => this.changeProperty(e, idx)}
                          value={item.property}
                        />
                      </td>
                      <td className="fixedTableCell">
                        <input type="number" min="0" max="100" className="standard-input" value={item.allocation} onChange={(e) => this.changeAllocation(e, idx)} />
                      </td>
                      <td className="fixedTableCell">
                        <Select
                          options={AllocationsManagementComponent.AllocationRolesSelect}
                          styles={reactSelectBasicStyle}
                          value={item.role}
                          onChange={(e: SelectOptions) => this.changeRole(e, idx)}
                        />
                      </td>
                      <td className="fixedTableCell">
                        <FontAwesomeIcon icon={faTrash} style={{ marginLeft: "20%" }} onClick={() => this.removeRow(idx)} />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <Row>
              <Col>
                <hr />
                {this.props.employee.value !== "" && <input type="button" value="Add Row" className="standard-input" onClick={this.addRow} />}
              </Col>
              <Col></Col>
            </Row>
          </Row>
          <br />
          <hr />
          <Row>
            <h5 style={{ width: "100%", textAlign: "center" }}>Difference</h5>
            <table className="fixedTable">
              <thead>
                <FilteredWithSettingsTableHeader columns={this.getHeaders()} icons={0} />
              </thead>
              <tbody>
                {
                  this.state.differences.map((item) => (
                    <tr key={item.uid}>
                      <td className="fixedTableCellFirst" style={{ textDecoration: item.removed ? "line-through" : "", color: item.removed ? "" : "" }} >{item.employee}</td>
                      <td className="fixedTableCell" style={{ textDecoration: item.removed ? "line-through" : "", color: item.removed ? "" : "" }} >{item.previousProperty} =&gt; {item.newProperty}</td>
                      <td className="fixedTableCell" style={{ textDecoration: item.removed ? "line-through" : "", color: item.removed ? "" : "" }} >{item.oldDistribution}% =&gt; {item.newDistribution}%</td>
                      <td className="fixedTableCell" style={{ textDecoration: item.removed ? "line-through" : "", color: item.removed ? "" : "" }} >{item.oldRole} =&gt; {item.newRole} </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Row>
          <br />
          <hr />
          <Row>
            <Col>
              <input type="button" value="Back" className="standard-input" onClick={() => this.props.updateTab(false)} />
            </Col>
            <Col>
              <input type="button" value="Next" className="standard-input" onClick={() => this.advance()} />
            </Col>
          </Row>
        </>
      </>
    )

  }
}